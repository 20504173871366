import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import ThankYouSection from "../../components/thank-you-section";
import { graphql } from "gatsby";
import Layout from "@layout";
import SEO from "@components/seo";
import { normalizedData } from "@utils/functions";

const Blog1 = ({ data, location, pageContext }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            <SEO title='Blog' pathname='/' />
            <Container>
                <ThankYouSection
                    image='https://reliance-cdn.dev.quanticdynamics.cloud/img/news/Reliance%20Insurance%20Limited%20secured%20Silver%20Award.png'
                    name='blog1'
                    designation='image'
                    title='Reliance Insurance secured Silver Award (2nd Position) for the Annual Report 2020 in 8th ICSB National Award for Corporate Govenance Excellence'
                    disc1='Reliance Insurance secured Silver Award (2nd Position) for the Annual Report 2020 in 8th ICSB National Award for Corporate Govenance Excellence'
                />
            </Container>
        </Layout>
    );
};
Blog1.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query Blog1Query {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allArticle {
            nodes {
                ...Articles
            }
        }
    }
`;

export default Blog1;
